import qs from "qs"
import { pickBy, identity, get, isString, assignIn } from "lodash"
import { INDEX_NAME, SEARCH_BASE_URL, FIELD_TYPE_MAP } from "../config"

/**
 * Create search result url from algolia search state data
*/
const createURL = state => {
    let myarea = state.menu
    // Ref: algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/#example-of-implementation
    const defaultSearch =
        !state.query &&
        state.page === 1 &&
        state.menu &&
        !myarea["building"] &&
        //state.range &&
        !state.range?.price &&
        !state.range?.bedroom

    if (defaultSearch) {
        return ""
    }

    let pricePath = "",
        bedroomPath = "",
        pagePath = "",
        propertypath = "",
        sortPath = "",
        areaPath = "";
    const queryParameters = {}

    if (state.query) {
        if (state.query) {
            areaPath = `/in-${encodeURIComponent(
                decodeURIComponent(state.query)
            )}`
        }
    }

    // Properties path
    if (state.menu) {
        if (myarea["building"]) {
            propertypath = `/type-${myarea["building"]}`
        }
    }
    if (get(state, 'refinementList.building', false)) {
        let b = state.refinementList.building;
        if (isString(b))
            b = [b]
        propertypath = `/type-${b.join('-and-')}`
    }

    // Price
    if (state?.range) {
        // Bedrooms
        if (
            state.range.hasOwnProperty("bedroom") &&
            state.range.bedroom.max === 0
        ) {
            bedroomPath = `/studio` //queryParameters.price_max = state.range.price.max;
        }
        if (state.range.bedroom) {
            if (state.range.bedroom.min) {
                bedroomPath = `/${state.range.bedroom.min}-and-more-bedrooms` //queryParameters.price_max = state.range.price.max;
            }
        }

        if (state?.range?.price) {
            if (state.range.price.min && state.range.price.max) {
                pricePath = `/between-${state.range.price.min}-and-${state.range.price.max}` //queryParameters.price_min = state.range.price.min;
            } else if (state.range.price.max) {
                pricePath = `/under-${state.range.price.max}` //queryParameters.price_max = state.range.price.max;
            } else if (state.range.price.min) {
                pricePath = `/over-${state.range.price.min}` //queryParameters.price_max = state.range.price.max;
            }
        }
    }

    // search_type
    let search_type = "sales"
    if (state?.search_type);
    search_type = state.search_type

    // layout
    let layout = state?.layout || "";
    if (!layout && layout !== 'default' && window.location.pathname.match(/\/properties\/map/)) {
        layout = `map/`
    }
    if ( layout === 'default' )
        layout = ""

    // Sort path
    if (state.sortBy) {
        if (state.sortBy === `${INDEX_NAME}_bedroom_asc`)
            sortPath = "/sortby-bedroom-asc"
        else if (state.sortBy === `${INDEX_NAME}_bedroom_desc`)
            sortPath = "/sortby-bedroom-desc"
        else if (state.sortBy === `${INDEX_NAME}_price_asc`)
            sortPath = "/sortby-price-asc"
    }
    // Sort path

    // Page path
    if (state.page && state.page !== 1) {
        pagePath = `/page-${state.page}`
    }
    // Page path

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat"
    })

    let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`

    if ("/" === myUrl.substr(myUrl.length - 1))
        myUrl = myUrl.substr(0, myUrl.length - 1)
    return `${SEARCH_BASE_URL}/${layout}${search_type}${myUrl}`
}

/**
 * Create Search Url from saved search params
*/
const createSearchUrl = (params, settings={}) => {

    let pricePath = "",
        bedroomPath = "",
        pagePath = "",
        propertypath = "",
        sortPath = "",
        areaPath = "",
        layoutPath = "",
        search_typePath = "";

    let defaultOptions = {
        useStatus:true,
        statusDelimiter: '/',
        areaPrefix:"in-",
        typePrefix:"type-",
        bedroomSuffix: "-and-more-bedrooms",
        basePath: {
            residential: 'property',
            commercial: 'commercial',
            new_developments: 'new-homes'
        },
        minPricePrefix: 'over',
        maxPricePrefix: 'under',
        useDefaultLocation: true
    }

    let settingOptions = assignIn(defaultOptions, settings)

    if (params?.areas) {
        const areas = params?.areas;
        if (typeof areas == "object") {
            var x = areas.toString();
            if(x.includes(',')) {
                areaPath = `/${defaultOptions.areaPrefix}${x.replace(RegExp(',','g'), '-and-').toLowerCase()}`
            } else{
                areaPath = `/${defaultOptions.areaPrefix}${x.replace(RegExp(' ','g'), '-').toLowerCase()}`
            }
        } else {
            areaPath = `/${defaultOptions.areaPrefix}${params.areas.replace(RegExp(' ','g'), '-').toLowerCase()}`
        }
    }

    if (!settingOptions.useDefaultLocation && params?.areas) {
        const areas = (params?.areas || '').toLowerCase();
        if (areas && areas === settingOptions?.defaultArea) {
            areaPath = '';
        }
    }

    if (!areaPath && settingOptions.useDefaultLocation && settingOptions?.defaultArea) {
        areaPath = `/${defaultOptions.areaPrefix}${(settingOptions?.defaultArea || '').replace(RegExp(' ','g'), '-').toLowerCase()}`
    }

    if (params?.building) {
        const building = params.building;
        if (typeof building === 'array' || typeof building === 'object') {
            var x = building.toString();
            if(x.includes(',')) {
                areaPath = `/${defaultOptions.typePrefix}${x.replace(RegExp(',','g'), '-and-').toLowerCase()}`
            } else{
                areaPath = `/${defaultOptions.typePrefix}${x.replace(RegExp(' ','g'), '-').toLowerCase()}`
            }
        } else {
            propertypath = `/${defaultOptions.typePrefix}${building.toLowerCase()}`
        }
    }

    if (params?.bedroom) {
        if (params?.bedroom === 0 )
            bedroomPath = `/studio`
        else
            bedroomPath = `/${params?.bedroom}${defaultOptions.bedroomSuffix}`
    }

    if(params?.minBedroom && params?.maxBedroom){
        bedroomPath = `/from-${params.minBedroom}-bedrooms-to-${params.maxBedroom}-bedrooms`
    } else if(params?.maxBedroom){
        bedroomPath = `/to-${params.maxBedroom}-bedrooms`
    } else if(params?.minBedroom){
        bedroomPath = `/from-${params.minBedroom}-bedrooms`
    }


    if (params.minPrice && params.maxPrice) {
        pricePath = `/between-${params.minPrice}-and-${params.maxPrice}`
    } else if (params.maxPrice) {
        pricePath = `/${settingOptions?.maxPricePrefix}-${params.maxPrice}`
    } else if (params.minPrice) {
        pricePath = `/${settingOptions?.minPricePrefix}-${params.minPrice}`
    }

    if (params.sortBy) {
        if (params.sortBy === `bedroom-asc`)
            sortPath = "/sortby-bedroom-asc"
        else if (params.sortBy === `bedroom-desc`)
            sortPath = "/sortby-bedroom-desc"
        else if (params.sortBy === `price-asc`)
            sortPath = "/sortby-price-asc"
    }

    if (params.page && params.page !== 1) {
        pagePath = `/page-${params.page}`
    }

    if (params.search_type) {
        search_typePath = `/${params.search_type}`
    }

    if (settingOptions?.useStatus) {
        let status = 'for-sale';
        if (params?.search_type === 'lettings')
            status = 'to-rent';
        search_typePath = `${settingOptions.statusDelimiter}${status}`
    }

    if (params.layout) {
        layoutPath = `/${params.layout}`
    }

    let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${sortPath}${pagePath}`

    if ("/" === myUrl.substr(myUrl.length - 1))
        myUrl = myUrl.substr(0, myUrl.length - 1)

    // format base url
    let baseUrlDefaults = get(settingOptions, `basePath.${params.department}`) || 'property';

    if (get(settingOptions, `basePath.${params?.department}`)) {
        if (baseUrlDefaults === settingOptions.basePath[params.department])
            baseUrlDefaults = `${settingOptions.basePath[params.department]}`
        else
            baseUrlDefaults = `${baseUrlDefaults}/${settingOptions.basePath[params.department]}`
    }
    return `/${baseUrlDefaults}${layoutPath}${search_typePath}${myUrl}`

}

const searchStateToUrl = (location, searchState) => {
    return searchState ? `${createURL(searchState)}` : ""
}

/**
 * Create algolia search state from URL result URL
*/
const urlToSearchState = (location, search_type, layout, parseUrl = false) => {
    if (!layout || layout === undefined) layout = ""
    else layout = `${layout}/`

    let query = [`search_type=${search_type}`]
    let areaVal,
        minpriceVal,
        maxpriceVal,
        pageVal,
        buildingVal,
        sortVal,
        sortVal_filt
    let bedVal = 10
    if (location) {
        let pathUri_main = window.location.pathname.split(
            `${SEARCH_BASE_URL}/${layout}${search_type}`
        )

        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")
            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }

                // Properties type
                if (pathUri[vi].indexOf("type-") >= 0) {
                    buildingVal = pathUri[vi].replace("type-", "")
                    if (buildingVal.match(/-and-/))
                        buildingVal = buildingVal.split('-and-')
                }

                // Area
                if (pathUri[vi].indexOf("in-") >= 0) {
                    areaVal = pathUri[vi].replace("in-", "")
                }

                // Price
                if (
                    pathUri[vi].indexOf("between-") >= 0 ||
                    pathUri[vi].indexOf("over-") >= 0 ||
                    pathUri[vi].indexOf("under-") >= 0
                ) {
                    let priceFilt1 = pathUri[vi].split("over-")
                    if (priceFilt1[1]) {
                        minpriceVal = priceFilt1[1]
                    }
                    let priceFilt2 = pathUri[vi].split("under-")
                    if (priceFilt2[1]) {
                        maxpriceVal = priceFilt2[1]
                    }
                    let priceFilt3 = pathUri[vi].split("between-")
                    if (priceFilt3[1]) {
                        let priceFilt4 = priceFilt3[1].split("-and-")
                        minpriceVal = priceFilt4[0]
                        maxpriceVal = priceFilt4[1]
                    }
                }

                // Bedrooms
                if (pathUri[vi].indexOf("-and-more-") >= 0) {
                    bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
                }

                if (pathUri[vi].indexOf("studio") >= 0) {
                    // its studio bedroom
                    bedVal = 0
                }

                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                    sortVal_filt = pathUri[vi].replace("sortby-", "")

                    if (sortVal_filt === "price-asc")
                        sortVal = `${INDEX_NAME}_price_asc`
                    else if (sortVal_filt === "price-desc")
                        sortVal = `${INDEX_NAME}`
                    else if (sortVal_filt === "bedroom-asc")
                        sortVal = `${INDEX_NAME}_bedroom_asc`
                    else if (sortVal_filt === "bedroom-desc")
                        sortVal = `${INDEX_NAME}_bedroom_desc`
                }

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }
            }
        }
    }

    if (parseUrl) {
        // If no area send default to set default loaction
        // We could send defaut location
        // if (!areaVal || areaVal === "undefined") {
        //     areaVal = DEFAULT_LOCATION
        // }

        const params = {
            search_type: search_type,
            areas: areaVal,
            bedroom: bedVal < 10 ? bedVal : "",
            building: buildingVal,
            minPrice: minpriceVal,
            maxPrice: maxpriceVal,
            sortBy: sortVal_filt,
            page: pageVal,
            layout: layout.replace(/\/$/, "")
        }

        if (sortVal) {
            let splitStr = sortVal_filt.split('-');
            params['sortBy'] = splitStr[0]
            params['order'] = splitStr[1]
        }

        return pickBy(params, identity)
    }

    // Properties type
    if (buildingVal) {
        let buildingFieldMap = get(FIELD_TYPE_MAP, 'building', false);
        if (buildingFieldMap) {
            let b = buildingVal;
            if (isString(buildingVal))
                b = [buildingVal]

            b.map((v) => {
                query.push(`${buildingFieldMap}=${v}`)
            })
        } else {
            query.push(`menu[building]=${buildingVal}`)
        }
    }

    // Area
    if (areaVal) {
        areaVal = areaVal.toLowerCase()
        query.push(`query=${areaVal}`)
    }

    // Price
    if (minpriceVal) {
        query.push(`range[price][min]=${minpriceVal}`)
    }

    if (maxpriceVal) {
        query.push(`range[price][max]=${maxpriceVal}`)
    }

    // Bedrooms
    if (bedVal === 0) {
        query.push(`range[bedroom][min]=0`)
    } else if (bedVal < 10) {
        query.push(`range[bedroom][min]=${bedVal}`)
    }

    // Page
    if (pageVal) {
        query.push(`page=${pageVal}`)
    }

    // Sort by
    if (sortVal) {
        query.push(`sortBy=${sortVal}`)
    }
    query = query.join("&")

    return qs.parse(query)
}

/* addtional functions. following functions are need optomization */
export const ACTION_TYPES = {
    sales: " for sale ",
    lettings: " to rent "
}

export const toTitleCase = text => {
    let _text = text || ""
    if (_text) {
        _text = _text.trim().split(" ")
        if (_text.length > 0) {
            _text = _text
                .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
                .join(" ")
        }
    }
    return _text
}

export const getPriceStr = (minPrice, maxPrice) => {
    let priceStr = ""
    if (minPrice && maxPrice) {
        priceStr =
            " between " +
            `£` +
            addCommas(minPrice) +
            " and " +
            `£` +
            addCommas(maxPrice)
    } else if (maxPrice) {
        priceStr = ` under £${addCommas(maxPrice)}`
    } else if (minPrice) {
        priceStr =  ` over £${addCommas(minPrice)}`
    }
    return priceStr
}

function addCommas(num) {
    var str = num.toString().split(".")
    if (str[0].length >= 4) {
        // add comma every 3 digits before decimal
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
    }
    return str.join(".")
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (state, layout) => {

    let sortBy = "",
        page = "";

    if (state.sortBy) {
        if (state.sortBy === `${INDEX_NAME}_bedroom_asc`)
            sortBy = "bedroom-asc"
        else if (state.sortBy === `${INDEX_NAME}_bedroom_desc`)
            sortBy = "bedroom-desc"
        else if (state.sortBy === `${INDEX_NAME}_price_asc`)
            sortBy = "price-asc"
    }

    if (state.page && state.page !== 1) {
        page = state.page
    }

    const params = {
        search_type: state?.search_type,
        layout: layout,
        areas: state?.query,
        bedroom: get(state, 'range.bedroom.min', "").toString(),
        building: get(state, 'menu.building', "").toString(),
        minPrice: get(state, 'range.price.min', "").toString(),
        maxPrice: get(state, 'range.price.max', "").toString(),
        sortBy: sortBy,
        page: page
    }

    return pickBy(params, identity)
}

// TODO: hard coded details to be removed/replaced with common variables
export const propertyH1 = params => {
    const {
        areas,
        // bedroom,
        search_type,
        building,
        minPrice,
        maxPrice,
        extra
    } = params

    let h1 = ""
    let buildingStr = ""

    if ("Commercial" === search_type) {
        // TODO
        // Commercial Properties [action] in [default search area] for Sale and Rent
        buildingStr = "Commercial "

        if (building) buildingStr += ` ${building} `
        else buildingStr += ` Properties `

        h1 += `${buildingStr}`

        if (areas) {
            h1 += ` in ${areas}`
        }

        h1 += ` for sale and rent`
    } else {
        let buildingStr = "Properties"
        // if (bedroom) buildingStr = "properties"
        //
        // if (bedroom) {
        //     let bedPlus = extra?.bedPlus ? "+" : "+"
        //     h1 += bedroom + bedPlus + " Bedroom "
        // }

        if (building) {
            buildingStr = ` ${building} `
        } else {
            buildingStr = ` Properties `
        }

        h1 += toTitleCase(buildingStr)
        h1 += ACTION_TYPES[search_type]

        if (areas) {
            let _areas = areas
            _areas = _areas.replace(RegExp('-','g'), ' ')
            _areas = decodeURIComponent(_areas)
            _areas = extra?.areasTitleCase ? toTitleCase(_areas) : _areas
            h1 += ` in ${_areas}`
        }

        if (minPrice || maxPrice) {
            h1 += getPriceStr(minPrice, maxPrice)
        }
    }

    return h1
}

const loadSearchParams = (searchParams, empty=true) => {
    let params = {
        search_type: get(searchParams, 'search_type', ''),
        areas: get(searchParams, 'areas', ''),
        bedroom: get(searchParams, 'bedroom', '').toString(),
        building: get(searchParams, 'building', '').toString(),
        minPrice: get(searchParams, 'minPrice', '').toString(),
        maxPrice: get(searchParams, 'maxPrice', '').toString(),
        sortby: get(searchParams, 'sortBy', 'price').toString(),
        order: get(searchParams, 'order', 'desc'),
        page: get(searchParams, 'page', '')
    }
    if (!empty)
        return pickBy(params, identity)
    return params
}

const savedSearchParams = (params) => {

  const { areas, bedroom, bedrooms, minPrice, maxPrice, department, pType, search_type, type, building, building_ids, feature_ids } = params;

  const searchParams = {
    department: (department || '').toLowerCase(),
    search_type: (pType || search_type || '').toLowerCase(),
    areas: areas,
    bedroom: bedrooms || bedroom,
    minPrice:  minPrice,
    maxPrice:  maxPrice,
    building: (type || building_ids || building || '').toLowerCase(),
    feature: feature_ids || ''
  }

  return pickBy(searchParams, identity)
}

export { createURL, searchStateToUrl, urlToSearchState, createSearchUrl, addCommas, loadSearchParams, savedSearchParams }
